import React, { useEffect, useState } from "react";
import { Button, Heading, Text } from "../../components";
import { default as ModalProvider } from "react-modal";
import { getCategoryByType, getCredits } from "utils/utils";
import { addGoogleMapTask, createCustomPortal, getCreditCost } from "api/auth";
import { toast } from "react-toastify";
import { Spinner } from "components/Spinner";
import { useNavigate } from "react-router-dom";
import CustomInput from "components/CustomInput";
import { NEARBY_AREA, SELECTEE_STATES, WHOLE_USA } from "config/config";
export default function TaskConfirmation({
  isOpen,
  handleClose,
  token,
  states,
  queries,
  tab,
  taskName,
  count,
  nearby,
  setTab,
  setCount,
  ...props
}) {
  const [creditPrice, setCreditPrice] = useState(undefined);
  const wholeCount = getCategoryByType(queries[0]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [cost, setCost] = useState(undefined);
  useEffect(() => {
    if(isOpen) {
      const getPrice = async () => {
        try {
          const data = await getCreditCost(token);
          setCreditPrice(data.credits);
          setCost(calculateCostRange(data.credits));
        } catch (error) {
          console.error("Error:", error);
        }
      };
      getPrice();
    }
  }, [isOpen]);
  function calculateCostRange(creditPrice) {
    const cost = getCredits(count, creditPrice);
    const discountPercent = 0.1;
    const upperCost = cost;
    let lowerCost = cost * (1 - discountPercent);
    lowerCost = Math.max(lowerCost, 2);
    return {
      upperCost: upperCost.toFixed(2),
      lowerCost: lowerCost.toFixed(2),
      cost,
    };
  }

  // const cost = calculateCostRange();
  const handlePayment = async () => {
    setLoading(true);
    await createCustomPortal(token)
      .then((data) => {
        window.open(data.url, "_blank");
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => setLoading(false));
  };
  function handleSwitchUsa() {
    setTab(WHOLE_USA);
    setCount(wholeCount);
  }
  const handleSubmit = async () => {
    try {
      if (tab === NEARBY_AREA) states = [];
      // if (nearby.zip_count < 250) {
      //   toast.info("Expand Area or select other location minimum 250 zipcodes");
      //   handleClose();
      //   return;
      // }
      else {
        nearby = {};
        if (tab === WHOLE_USA) states = [];
      }
      setLoading(true);

      let payload = {
        searchOption: tab,
        count,
        queries,
        name: taskName,
        coupon,
      };
      if (tab === NEARBY_AREA) payload.nearby = nearby;
      else if (tab === SELECTEE_STATES) payload.states = states;
      const data = await addGoogleMapTask(token, payload);
      toast.success("Your will receive email when finished");
      setLoading(false);
      navigate("/results");
    } catch (error) {
      console.error("Error:", error);
      const msg =
        error.response?.data?.message ||
        "An error occurred. Please try again later.";
      if (msg === "Billing method not specified") {
        await handlePayment();
      } else {
        toast.error(msg);
        setLoading(false);
      }
    }
  };
  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isOpen}
      className="min-w-[100%] h-[100%] overflow-auto"
    >
      <div className="flex justify-center w-full px-14 py-[122px] md:p-5 sm:p-2">
        <div className="flex flex-col items-start justify-center w-[63%] md:w-full mb-32 gap-4 p-7 sm:p-5 bg-white-A700 rounded-[16px]">
          <Text size="lg" as="p" className="ml-[3px] md:ml-0 tracking-[0.18px]">
            Task Confirmation ({taskName})
          </Text>
          <div className="self-stretch h-px ml-[3px] md:ml-0 bg-gray-50" />
          <Text as="p" className="ml-[3px] md:ml-0 tracking-[0.15px]">
            You’re going to process queries for {tab}:
          </Text>
          {tab === NEARBY_AREA && (
            <Text as="p" className="ml-[3px] md:ml-0 tracking-[0.15px]">
              Total zipcodes {nearby.zip_count}, radius {nearby.radius / 1000}KM
              and center {nearby.zipcode}
            </Text>
          )}

          <div className="flex flex-col items-start justify-center ml-[3px] gap-[9px] md:ml-0 max-w-md overflow-auto">
            {queries.map((txt, index) => (
              <Text
                as="p"
                className="!text-teal-600 tracking-[0.15px] underline"
                key={index}
              >
                {txt}
              </Text>
            ))}
            {/* <Heading as="h1" className="tracking-[0.15px]">
                            <span className="text-gray-900">... 70,823&nbsp;</span>
                            <span className="text-gray-900 font-normal">more queries</span>
                        </Heading> */}
          </div>

          <div className="flex flex-col self-stretch ml-[3px] gap-[7px] md:ml-0">
            <div className="flex md:flex-col justify-center gap-2">
              <div className="w-full pt-[45px] pb-[19px] pl-4 pr-[35px] md:pt-5 sm:pr-5 text-gray-900 tracking-[0.15px] text-left text-base font-bold border-gray-50 border border-solid rounded-lg">
                {/* in 7 - 30 minutes */}
                <Text as="p" className="mt-[3px] tracking-[0.15px]">
                  Very rough estimate of usage based on number of queries
                </Text>
                <Heading as="h2" className="mb-[3px] tracking-[0.15px]">
                  {count}
                </Heading>
              </div>
              {/* FIXME:  */}
              {/* <div className="flex flex-col items-start justify-center w-full gap-[7px] p-4 border-gray-50 border border-solid rounded-lg">
                {tab !== WHOLE_USA && wholeCount !== undefined && (
                  <>
                    <Text
                      as="p"
                      className="mt-[3px] tracking-[0.15px] capitalize"
                    >
                      Run whole USA and get these many results{" "}
                      <strong>{wholeCount.toLocaleString()}</strong>
                    </Text>
                    <Heading
                      as="h2"
                      className="mb-[3px] tracking-[0.15px] cursor-pointer uppercase"
                      onClick={handleSwitchUsa}
                    >
                      Switch to whole USA
                    </Heading>
                  </>
                )}
              </div> */}
            </div>
            <CustomInput
              placeholder="Type coupon code if you have any"
              label="Coupon"
              required={false}
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
            />
            <div className="flex self-start gap-2 flex-wrap">
              <Text as="p" className="self-end tracking-[0.15px]">
                This is a very rough estimate, the actual amount may vary. the
                cost typically depends on the volume of data actually received.
              </Text>
              {/* <Text
                as="p"
                className="self-start !text-red-500 tracking-[0.15px]"
              >
                *
              </Text> */}
            </div>
          </div>
          <div className="flex flex-row sm:flex-col self-stretch mb-[3px] ml-[3px] gap-8 md:ml-0">
            {cost === undefined ? (
              <Spinner />
            ) : (
              <div className="flex sm:flex-col justify-between md:self-stretch gap-5 p-[11px] bg-gray-50 flex-1 rounded-[22px] sm:items-center">
                <div className="flex ml-1 gap-2 md:ml-0 flex-wrap sm:justify-center">
                  <Heading as="h2" className="tracking-[0.15px]">
                    Rough cost
                  </Heading>
                  <Text
                    as="p"
                    className="!text-gray-600 tracking-[0.15px] sm:invi"
                  >
                    (enrichments included)
                  </Text>
                </div>
                <Heading
                  as="h3"
                  className="mr-1 md:mr-0 !text-teal-600 tracking-[0.15px]"
                >
                  ${cost.lowerCost} - ${cost.upperCost}
                </Heading>
              </div>
            )}
            {loading ? (
              <Spinner />
            ) : (
              <div className="flex justify-between md:flex-col">
                <Button
                  shape="round"
                  className="sm:px-5 tracking-[1.25px] uppercase font-medium min-w-[150px]"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="gradient"
                  shape="round"
                  color="teal_300_teal_600"
                  className="sm:px-5 tracking-[1.25px] uppercase font-medium min-w-[150px]"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}
